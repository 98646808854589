<!--
 * @Author: 肖槿
 * @Date: 2020-06-12 15:44:42
 * @Description: 订单管理
 * @LastEditors: 矢车
 * @LastEditTime: 2020-08-10 12:25:59
 * @FilePath: \s2b-admin\src\pages\admin\shop\order-management.vue
-->
<template>
  <div id="order-management">
    <order-management-search
      :loading="listLoading"
      :currentTab="currentTab"
      :categoryList="categoryList"
      @success="searchHandler"
      @loadData="loadData"
    />
    <div style="position: relative">
      <a-tabs v-model="currentTab" @change="tabChangeCallback" type="card">
        <a-tab-pane v-for="v in OrderStatus" :key="`${v.value}`" :tab="v.label"></a-tab-pane>
      </a-tabs>
      <a-button
        v-auth="'order_manage_export'"
        style="position: absolute; top: 0; right: 0"
        type="primary"
        @click="exportHandler"
        >导出订单</a-button
      >
    </div>

    <div v-auth="'order_manage_page'" style="min-width: 1400px; overflow-x: auto">
      <div class="order-table-header">
        <div class="header-item" v-for="(v, k) in OrderTable" :key="k" :style="{ flex: v.width }">
          {{ v.title }}
        </div>
      </div>
      <div class="order-table-body" v-if="list.length !== 0">
        <div class="body-item" v-for="v in list" :key="v.id">
          <div class="body-item-title">
            <span>主订单编号：{{ v.xd_order_id }}</span>
            <span>下单时间：{{ v.create_time | timeFormat('YYYY-MM-DD HH:mm') }}</span>
            <span>完成时间：{{ v.finish_time | timeFormat('YYYY-MM-DD HH:mm') }}</span>
          </div>
          <div class="body-item-container" v-for="(n, m) in v.order_items" :key="n.id">
            <div class="container-item">{{ n.merchant_name }}</div>
            <div class="container-item">{{ n.xd_order_id }}</div>
            <div class="container-item">
              <my-image v-if="n.sku_img_url" :imgUrl="n.sku_img_url"></my-image>
              <span style="padding-left: 5px">{{ n.spu_name }}</span>
            </div>
            <div class="container-item">{{ n.supply_price | thousandsFormat }}</div>
            <div class="container-item">{{ n.xd_price | thousandsFormat }}</div>
            <div class="container-item">{{ n.sku_quantity }}</div>
            <div class="container-item">
              <a-tooltip
                v-if="n.buyer_words || n.seller_words"
                placement="topLeft"
                overlayClassName="tooltip-class"
              >
                <template slot="title">
                  <div>客户备注：{{ n.buyer_words || '--' }}</div>
                  <div>商家备注：{{ n.seller_words || '--' }}</div>
                </template>
                <a-button type="link" size="small" icon="profile">备注</a-button>
              </a-tooltip>
              <a-button v-else disabled type="link" size="small" icon="profile">备注</a-button>
            </div>
            <div class="container-item">
              {{
                n.final_status === 11 && n.confirm_statu === 1
                  ? '退货中-商家已收货'
                  : n.final_status === 11 && n.confirm_statu === 0
                  ? '退货中-用户填写完物流'
                  : '--'
              }}
            </div>
            <div
              class="container-item"
              :style="m !== v.order_items.length - 1 ? 'border-bottom:0;' : ''"
            >
              <div v-show="m === 0">
                <div>姓名：{{ v.receiver_name }}</div>
                <div>手机：{{ v.receiver_phone }}</div>
                <div>
                  地址：{{
                    getAddressSync(v.receiver_province, v.receiver_city, v.receiver_region, '/')
                  }}/{{ v.receiver_detail_address }}
                </div>
              </div>
            </div>
            <div class="container-item itemFlex">
              <div>{{ getStatus(n, v) }}</div>
              <div class="logistaics" v-if="n.delivery_flag === 1">
                <a-popover
                  :destroyTooltipOnHide="true"
                  @mouseenter="getLogistaicsInfo(n.id)"
                  placement="left"
                >
                  <template slot="content">
                    <a-table :columns="tooltip_columns" :dataSource="tooltipList"></a-table>
                    <!-- <div class="close_popover">
                      <a-button size="small">关闭</a-button>
                    </div> -->
                  </template>
                  <template slot="title">{{ logistics_detail }}</template>
                  <a>查看物流</a>
                </a-popover>
              </div>
            </div>
            <div
              class="container-item"
              :style="m !== v.order_items.length - 1 ? 'border-bottom:0;' : ''"
            >
              <div v-show="m === 0">实付金额：￥{{ v.order_total_amount | thousandsFormat }}</div>
            </div>
            <div class="container-item">{{ n.settlement_status | shopMargin(Settlement) }}</div>
          </div>
        </div>
      </div>
      <div v-else class="order-table-body" style="text-align: center">暂无数据</div>
      <div style="text-align: right; margin-top: 20px">
        <a-pagination
          :current="pagination.page"
          :defaultPageSize="pagination.pageSize"
          :showTotal="pagination.showTotal"
          :total="pagination.total"
          v-bind="pagination"
          @change="paginationHandler"
        />
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { OrderManagementSearch } from '@/components/admin/shop';
import { OrderStatus, OrderTable, OrderStatusInfo, Settlement } from '@/const/shop';
import { deepClone } from '@/utils/';
import { getCategory, getOrderList, getLogisiticsRecent } from '@/service/shop';
import { baseURL } from '@/config';
import { shopMargin } from '@/filters/shop';
import { ObjectFilterEmpty, URLSearchMaker } from '@/tslibs/utils/func';
import { Get } from '@/tslibs/utils/request';
import { getAddressSync } from '@/tslibs/utils/func';

const tooltip_columns = [
  {
    title: '时间',
    dataIndex: 'time',
    width: 180,
  },
  {
    title: '物流信息',
    dataIndex: 'context',
    width: 300,
  },
];

const _OrderStatus = deepClone(OrderStatus);

_OrderStatus.unshift({
  label: '全部',
  value: 0,
});

export default {
  name: 'OrderManagement',
  components: {
    OrderManagementSearch,
  },
  data() {
    return {
      tooltip_columns,
      tooltipList: [],
      logistics_detail: '', //  物流信息和单号
      OrderStatus: _OrderStatus,
      OrderStatusInfo,
      getOrderList,
      OrderTable,
      baseURL,
      Settlement,
      currentTab: '0',
      listLoading: false,
      categoryList: [],
      list: [],
      queryData: {},
      pagination: {
        page: 1,
        page_size: 10,
        total: 0,
        showQuickJumper: false,
        showSizeChanger: false,
        showTotal: total =>
          `共 ${total} 条记录 第 ${this.pagination.page}/${Math.ceil(
            total / this.pagination.page_size,
          )} 页`,
      },
      shipCache: new Map(),
    };
  },
  async mounted() {
    this.getTableData();
    this.categoryList = await this.getCategory(0);
  },
  methods: {
    // 显示当前物流信息
    async getLogistaicsInfo(order_item_id) {
      const key = `${order_item_id}`;
      if (this.hasShipMsg(key)) {
        let tempList = this.getShipMsg(key);
        this.tooltipList = tempList.logistics_info.data;
        this.logistics_detail = `${tempList.logistics_company} - 单号：${tempList.logistics_time}`;
        return;
      }

      const { err, res } = await getLogisiticsRecent(order_item_id);
      if (!err) {
        if (res.success) {
          this.tooltipList = res.data.logistics_info.data;
          this.logistics_detail = `${res.data.logistics_company} - 单号：${res.data.logistics_time}`;
          this.shipCache.set(key, res.data);
        }
      }
    },
    // 判断物流信息是否已缓存
    hasShipMsg(key) {
      return this.shipCache.has(key);
    },
    // 设置物流信息缓存
    setShipMsgCache(key, val) {
      this.shipCache.set(key, val);
    },
    // 获取物流信息缓存
    getShipMsg(key) {
      if (this.hasShipMsg(key)) {
        return this.shipCache.get(key);
      }
    },
    tabChangeCallback(val) {
      if (val === '0') {
        delete this.queryData.order_status;
      } else {
        this.queryData.order_status = val;
      }
      this.currentTab = val;
      this.pagination.page = 1;
      this.getTableData();
    },
    searchHandler(data) {
      if (Object.keys(data).length === 0) {
        this.currentTab = '0';
      }
      if (Array.isArray(data.create) && data.create.length) {
        data.create_time =
          moment(data.create[0]).unix() +
          ',' +
          moment(data.create[1])
            .add(24 * 59, 'minutes')
            .unix();
      }
      if (Array.isArray(data.finish) && data.finish.length) {
        data.finish_time =
          moment(data.finish[0]).unix() +
          ',' +
          moment(data.finish[1])
            .add(24 * 59, 'minutes')
            .unix();
      }
      this.queryData = deepClone(data);
      delete data.create_time;
      delete data.finish_time;
      delete this.queryData.create;
      delete this.queryData.finish;
      this.pagination.page = 1;
      this.getTableData();
    },
    async getTableData() {
      this.listLoading = true;
      delete this.queryData.page;
      const { page, page_size } = this.pagination;
      const _obj = {
        ...this.queryData,
        page,
        page_size,
      };

      if (this.currentTab !== '0') {
        _obj.order_status = this.currentTab;
      }

      const { res } = await this.getOrderList(_obj);
      this.pagination.total = res.data.total || 0;
      this.list = res.data.rows || [];
      this.listLoading = false;
    },
    async loadData(selectedOptions) {
      const targetOption = selectedOptions[selectedOptions.length - 1];
      targetOption.loading = true;
      targetOption.children = await this.getCategory(targetOption.id);
      if (targetOption.cat_level === 1) {
        targetOption.children.forEach(v => (v.isLeaf = true));
      }
      targetOption.loading = false;
      this.categoryList = [...this.categoryList];
    },
    async getCategory(pid) {
      const { res } = await getCategory(pid);
      res.data.forEach(v => (v.isLeaf = false));
      return res.data;
    },
    paginationHandler(current) {
      this.pagination.page = current;
      this.getTableData();
    },
    async exportHandler() {
      const url = '/api/operators/v1/merchant/internal/order/export/';
      const payload = ObjectFilterEmpty({ ...this.queryData, page: 1, page_size: 10000 });
      const params = URLSearchMaker(payload);

      const { data } = await Get(`${baseURL}${url}?${params}`, {
        responseType: 'blob',
        timeout: 120000,
      });

      if (data) {
        const link = document.createElement('a');

        const blob = new Blob([data], {
          type: 'application/vnd.ms-excel',
        });
        link.style.display = 'none';
        link.href = URL.createObjectURL(blob);
        link.setAttribute('download', `订单_${new Date(Date.now()).toJSON()}.xlsx`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    },
    /**
     * 获取订单状态文本
     * @author Jerry <superzcj_001@163.com>
     * @since  2020-07-21 10:56:31
     */
    getStatus(orderItem, order) {
      const { final_status, delivery_flag } = orderItem;

      // delivery_flag 为发货标记 0 未发货
      if (final_status === 3 && delivery_flag === 0) {
        return shopMargin(2, OrderStatusInfo);
      } else if (final_status === 255 && order.order_status === 5) {
        // 主订单5子订单255，小店后台是已完成，未明确给出文档
        return shopMargin(5, OrderStatusInfo);
      }

      return shopMargin(final_status, OrderStatusInfo);
    },
    getAddressSync,
  },
};
</script>

<style lang="less" scoped>
#order-management {
  .order-table-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fafafa;
    margin-bottom: 10px;

    .header-item {
      padding: 10px;
    }
    & .header-item:nth-child(2),
    & .header-item:nth-child(9),
    & .header-item:nth-child(3) {
      flex: none !important;
      width: 250px !important;
    }
  }
  .order-table-body {
    .body-item {
      position: relative;
      & .body-item-title {
        padding: 10px;
        background: #fafafa;
        border: 1px solid #ebebeb;
        border-top: 0;
        & span {
          display: inline-block;
          width: 250px;
        }
        & span:first-child {
          width: 350px;
        }
      }
      & .body-item-container {
        display: flex;
        justify-content: space-between;
        min-width: 1200px;
        & .container-item {
          flex: 1;
          padding: 10px;
          border-left: 1px solid #ebebeb;
          display: flex;
          align-items: center;
          border-bottom: 1px solid #ebebeb;
        }
        & .container-item:nth-child(1) {
          flex: none;
          width: 100px;
          word-break: break-all;
        }
        & .container-item:nth-child(2),
        & .container-item:nth-child(9),
        & .container-item:nth-child(3) {
          flex: none;
          width: 250px;
        }
        & .container-item:nth-child(9) {
          flex-direction: column;
          align-items: baseline;
        }
        & .container-item:last-child {
          border-right: 1px solid #ebebeb;
        }
      }
    }
    & > .body-item:first-child {
      & .body-item-title {
        border-top: 1px solid #ebebeb;
      }
    }
  }

  .itemFlex {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
</style>
<style lang="less">
.tooltip-class {
  .ant-tooltip-inner {
    background: #fff;
    color: #333;
  }
  & .ant-tooltip-arrow::before {
    background: #fff;
  }
}

.ant-popover-placement-left {
  .ant-popover-content {
    box-shadow: #bfbbbb 0px 0px 5px;
    max-height: 300px;
    overflow: auto;
  }

  .ant-table-scroll-position-left {
    font-size: 12px;
  }
}
</style>

<style scoped>
.ant-table-pagination {
  display: none;
}

.ant-popover-inner-content {
  display: none !important;
}
</style>
